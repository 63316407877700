<template>
  <div class="order_wrap" @scroll="handleScroll" >
    <div class="order_item" v-for="(item, index) in list" :key="index" >
      <div class="order_top" >
        <div>订单编号</div>
        <div>{{ item.id }}</div>
      </div>
      <div class="order_content" >
        <div class="order_content_item" >
          <div class="content_item" >
            <div>服务门店</div>
            <div>{{ item.shopName }}</div>
          </div>
          <div class="content_item" >
            <div>服务名称</div>
            <div>{{ item.productName }}</div>
          </div>
        </div>

        <div class="order_content_item" >
          <div class="content_item" >
            <div>支付金额</div>
            <div class="money_text" >￥{{ item.amount }}</div>
          </div>
          <div class="content_item" >
            <div>时间</div>
            <div>{{ item.createTime }}</div>
          </div>
        </div>
      </div>
    </div>

    <van-empty
      v-if="list.length <= 0 && !loading"
      :image="empty"
      description="暂无订单"
    />

  </div>
</template>
<style scoped lang="less" >
@import "index";
</style>
<script>
import {
  getCustomerList
} from '@/api/order'
import empty from '../report/img/6.png'
export default {
  name: 'order',
  data() {
    return {
      page: 1,
      pageSize: 10,
      list: [],
      loading: true,
      empty
    }
  },
  methods: {
    handleScroll(event) {
      const {scrollTop, clientHeight, scrollHeight} = event.target
      if (scrollTop + clientHeight >= scrollHeight - 50){
        this.page ++
        this.getCustomerList()
      }
    },
    getCustomerList() {
      const { page, pageSize } = this
      this.loading = true
      getCustomerList({ page, pageSize }).then(res => {
        this.loading = false
        if(res.success) {
          this.list = this.list.concat(res.data)
        }
      })
    }
  },
  mounted() {
    this.getCustomerList()
  }
}
</script>
